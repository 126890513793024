import "../public/css/uitleen.css";
import "./fontawesome_all.min.js";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";

const app = Elm.Main.init({
	node: document.getElementById("root"),
	flags: {
		endpoint: process.env.ELM_APP_PROD,
		api_key: process.env.ELM_APP_API_KEY,
	},
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ports:
app.ports.afvoerMsg.subscribe(function () {
	const a = document.getElementById("aantekeningen");
	a.focus();
	setTimeout(() => {
		a.scrollTop = a.scrollHeight + 20;
	}, 200);
});

app.ports.duplicateMsg.subscribe(function () {
	const a = document.getElementById("object_id");
	// console.log(a);
	a.style.border = "2px solid red";
	setTimeout(() => {
		a.style.border = "";
	}, 2500);
	a.focus();
});

app.ports.resetFile.subscribe(function () {
	const a = document.getElementsByClassName("foto-details");
	const arr = Array.from(a);
	arr.forEach((e) => {
		e.value = "";
	});
	setTimeout(() => {
		app.ports.receiveMsg.send("");
	}, 5000);
});

app.ports.setLocalStorageItem.subscribe(function ([key, value]) {
	localStorage.setItem(key, value);
});

app.ports.checkLocal.subscribe(function () {
	const a = localStorage.getItem("glectuUser");
	app.ports.loadLocalStorage.send(a || "");
});
